<template>
  <v-window-item value = "attest">
    <v-card-text :style="{'min-height': '300px', 'font-size': fontSize + 'em', 'line-height': lineHeight + 'em', 'text-align': $vuetify.breakpoint.mobile ? 'left' : 'justify'}">
      <p>{{ $t('consent.attest.agree') }}</p>
      <ul class="ml-5 mb-12">
        <li v-for="(a, i) in attestations" :key='i' v-html="a"></li>
      </ul>
      <v-row justify="center">
        <v-spacer></v-spacer>
        <v-btn 
          @click="$emit('show-consent')" 
          color="accent"
          class = "mb-5"
          large
          id="reviewConsentBtn"
        >
          {{ $t('consent.attest.review') }}
        </v-btn>   
      <v-spacer></v-spacer>
      </v-row>
      <v-row justify="center">
        <v-spacer></v-spacer>
        <v-col cols="12" md="10" lg="8">       
          <p>{{ $t('consent.attest.select-choice') }}</p>
          <v-radio-group v-model='status'>
            <v-radio v-if="UPSIT && GT" value = "consented" id="consentConsentedGT">
              <template slot="label">
                <span v-html="$t('consent.attest.consent-withgt')"></span>
              </template>
            </v-radio>
            <v-radio v-else value = "consented" id="consentConsented">
              <template slot="label">
                <span v-html="$t('consent.attest.consent')"></span>
              </template>
            </v-radio>

            <v-radio v-if="UPSIT && GT" value="consented_no_gt" id="consentConsentedNoGT"> 
              <template slot = "label">
                <span v-html="$t('consent.attest.consent-nogt')"></span>
              </template>
            </v-radio>

            <v-radio value = "declined" id="consentDeclined"> 
              <template slot = "label">
                <span v-html="$t('consent.attest.decline')"></span>
              </template>
            </v-radio>

            <v-radio value = "questions" id="consentQuestions">                    
              <template slot = "label">
                <span v-html="$t('consent.attest.questions')"></span>
              </template>
            </v-radio> 

          </v-radio-group>
          <v-btn 
            @click="$emit('submit')" 
            block 
            color="accent"
            :disabled="submitDisabled"
            id="submitBtn"
          >
            {{ $t('gen.submit') }}
          </v-btn>     
        </v-col>
        <v-spacer></v-spacer>
      </v-row>      
    </v-card-text>
  </v-window-item>
</template>

<script>
  export default {
    name: "attestationPage",
    props: {
      fontSize: {
        type: Number,
        required: true
      },
      lineHeight: {
        type: Number,
        required: true
      },
      allowSubmit: {
        type: Boolean,
        default: true
      },
    },
    computed: {
      submitDisabled: function() {
        return !this.allowSubmit;
      },
      UPSIT() {
        // return true
        return this.$store.getters['participant/prt_flags']["PPMI Remote: UPSIT"] || false
      },
      GT() {
        // return true
        return this.$store.getters['participant/prt_flags']["PPMI Remote: Genetic"] || false
      }, 
      attestations() {
        return this.$store.getters['consent/attestations']
      },
      status: {
        get() {
          return this.$store.getters['consent/status']
        },
        set(x) {
          this.$store.commit("consent/SET_STATUS", x)
        }
      }
    }
  }
</script>