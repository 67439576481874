<template>
  <v-window-item value="review">
    <v-card-text>
      <p>{{ $t('qnn.review.review') }}</p>
      <p v-html="$t('qnn.review.submit')"></p>
      <v-simple-table>
        <thead>
          <th>{{ $t('qnn.compact.number') }}</th>
          <th>{{ $t('qnn.compact.question') }}</th>
          <th>{{ $t('qnn.compact.answer') }}</th>
        </thead>
        <tbody>
          <template v-for="(question, index) in questions">
            <tr @click="goto(question.id)" :key="index" style="cursor: pointer;">
              <td>{{question.label}}</td>
              <td>{{question.text}}</td>
              <td v-html="formatAnswer(question.answer)"></td>
            </tr>           
          </template>
        </tbody>
      </v-simple-table>
    </v-card-text>  
  </v-window-item>
</template>

<script>

  export default {
    name: "questionnaireReview",
    computed: {
      questions() {
        return this.$store.getters['questionnaire/questions'].filter(x => !x.skipped)
      }
    },
    methods: {
      goto(page) {
        this.$store.commit("questionnaire/SET_PAGE", page)
      },
      formatAnswer(answer) {
        if(Array.isArray(answer)) {
          answer = answer.join("<br />")
        }
        return answer
      },
    }
  }

</script>