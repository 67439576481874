import data_api from "@/services/axios-data.js";
import validateGUID from "@/services/guids"
import Vue from 'vue'

const state = {
  page: 0,
  qnn_guid: null,
  date: null,
  header: null,
  pages: [],
  attestations: [],
  status: null,
  prt_full_name: null,
  prt_date: null,
  prt_sig: null,
  question: null,
  consent_body: null,
  declined_category: null,
  additional_comments: null,
  staff_contact: null,
  loading: false,
  consent_guid: null
}

const mutations = {
  SET_PAGE(state, payload) {
    state.page = payload
  },
  SET_QNN_GUID(state, payload) {
    if(validateGUID(payload)) {
      state.qnn_guid = payload.toLowerCase()
    } else {
      state.qnn_guid = null
    }
  },
  SET_DATE(state, payload) {
    state.date = payload
  },
  SET_HEADER(state, payload) {
    state.header = payload
  },
  SET_PAGES(state, payload) {
    state.pages = payload
  },
  SET_ATTESTATIONS(state, payload) {
    state.attestations = payload
  },
  SET_STATUS(state, payload) {
    state.status = payload
  },
  SET_PRT_FULL_NAME(state, payload) {
    state.prt_full_name = payload
  },
  SET_PRT_DATE(state, payload) {

    const event = new Date(payload);
    const options = { year: 'numeric', month: 'long', day: 'numeric', timeZone: 'UTC' };

    state.prt_date = event.toLocaleDateString(undefined, options)
  },
  SET_PRT_SIG(state, payload) {
    state.prt_sig = payload
  },
  SET_QUESTION(state, payload) {
    state.question = payload
  },
  SET_CONSENT_BODY(state, payload) {
    state.consent_body = payload
  },
  SET_DECLINED_CATEGORY(state, payload) {
    state.declined_category = payload
  },
  SET_ADDITIONAL_COMMENTS(state, payload) {
    state.additional_comments = payload
  },
  SET_STAFF_CONTACT(state, payload) {
    state.staff_contact = payload
  },
  SET_LOADING(state, payload) {
    state.loading = payload
  },
  SET_CONSENT_GUID(state, payload) {
    state.consent_guid = payload
  },
  LOGOUT(state) {
    state.page = 0
    state.qnn_guid = null
    state.date = null
    state.header = null
    state.pages = []
    state.attestations = []
    state.status = null
    state.prt_full_name = null
    state.prt_date = null
    state.prt_sig = null
    state.question = null
    state.consent_body = null
    state.declined_category = null
    state.additional_comments = null
    state.staff_contact = null
  }
}

const actions = {
  async load(context, qnn_guid) {
    try {
      let guid = qnn_guid || context.rootState.route.params.qnn
      context.state.loading = true
      let prt_guid = await context.rootGetters['participant/participant_guid']
      let resp = await data_api.get(['participants', prt_guid, 'consent', guid])
      Vue.$log.debug("consent load response", resp)
      let d = resp.data.consent_data
      context.commit("SET_QNN_GUID", d.guid)
      context.commit("SET_DATE", d.qnn_date);
      context.commit("SET_CONSENT_BODY", d.qnn_json.consent_body);
      context.commit("SET_PAGES", d.qnn_json.pages);
      context.commit("SET_ATTESTATIONS", d.qnn_json.attestations);
      if(resp.data.participant_data) {
        let pd = resp.data.participant_data
        context.commit("SET_ADDITIONAL_COMMENTS", pd.additional_comments)
        context.commit("SET_DECLINED_CATEGORY", pd.declined_category)
        context.commit("SET_PRT_DATE", pd.prt_date)
        context.commit("SET_PRT_SIG", pd.prt_sig)
        context.commit("SET_STATUS", pd.status)
        context.commit("SET_QUESTION", pd.question)
        context.commit("SET_STAFF_CONTACT", pd.staff_contact)
        context.commit("SET_CONSENT_GUID", pd.guid)
      }
      context.state.loading = false
      return resp.data
    } catch(err) {
      Vue.$log.error(err)
      context.state.loading = false
      throw new Error("Cannot load consent")
    }
  },
  async submit(context, payload) {
    context.state.loading = true
    let prt_guid = await context.rootGetters['participant/participant_guid']
    let locale = '?locale=' + context.rootGetters["userSettings/current_locale"]
    payload.qnn_guid = context.state.qnn_guid
    payload.prt_guid = prt_guid
    payload.email_sent = true
    try {
      let resp
      if(context.state.consent_guid) {
        payload.guid = context.state.consent_guid
        resp = await data_api.put(['participants', prt_guid, 'consent', context.state.qnn_guid, locale], payload)
      } else {
        resp = await data_api.post(['participants', prt_guid, 'consent', context.state.qnn_guid, locale], payload)
      }
      Vue.$log.info("submit consent resp", resp)
      context.commit("SET_PRT_SIG", payload.prt_sig)
      context.commit("SET_PRT_FULL_NAME", payload.prt_full_name)
      context.commit("SET_PRT_DATE", payload.prt_date)
      // Pull the updated participant data so that the user shows as consented on the home page
      await context.dispatch("participant/load", {userid: context.rootGetters['auth/user_id'], source: "consentReload"}, {root: true})
      context.state.loading = false
      return true
    } catch(err) {
      context.state.loading = false
      Vue.$log.error(err)
      context.dispatch("snackbar/display", {message: "There was a problem submitting your consent, please try again or contact us.", color: 'error'}, {root: true})
      return 'error'
    }
  },
  async getHTMLConsent(context) {

    let prt_guid = await context.rootGetters['participant/participant_guid']
    let qnn_guid = context.state.qnn_guid;
  //  let qnn_guid = "99F75A6B-9D3C-4DC3-AD53-C35745D35358"

    try {
      context.state.loading = true
      let resp = await data_api.get(['participants', prt_guid, 'consent', qnn_guid], {"format": "html"})
      Vue.$log.debug("html consent response", resp)
      context.state.loading = false
      return resp.data
    } catch(err) {
      Vue.$log.error(err)
      context.dispatch("snackbar/display", {message: "There was a problem submitting your consent, please try again or contact us.", color: 'error'}, {root: true})
    }

  }
}

const getters = {
  qnn_guid: state => state.qnn_guid,
  pages: state => state.pages,
  attestations: state => state.attestations,
  page: state => state.page,
  status: state => state.status,
  consent_body: state => state.consent_body,
  loading: state => state.loading,
  full_name: state => state.prt_full_name,
  sign_date: state => state.prt_date,
  signature: state => state.prt_sig,
  downloadLink: async (state, getters, rootState, rootGetters) => {
    let qnn_guid = state.qnn_guid
    let prt_guid = await rootGetters['participant/participant_guid']
    let url = `/participants/${prt_guid}/consent/${qnn_guid}?format=pdf`
    return url
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
